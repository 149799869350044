<script>
  // # # # # # # # # # # # # #
  //
  //  Main
  //
  // # # # # # # # # # # # # #

  // *** IMPORT
  import { Router, Route } from "svelte-routing"
  import { onMount } from "svelte"
  import { client } from "./sanity.js"
  import get from "lodash/get"

  // *** COMPONENTS
  import Orb from "./Orb.svelte"

  // ROUTES
  import Landing from "./Landing.svelte"
  import Editorial from "./Editorial.svelte"
  import About from "./About.svelte"
  import Spectrophilia from "./Spectrophilia.svelte"
  // CYCLE 1
  import CycleOne from "./CycleOne.svelte"
  import EEEFFF from "./eeefff/EEEFFF.svelte"
  import OlofMarsja from "./olof-marsja/OlofMarsja.svelte"
  import AlinaChaiderov from "./alina-chaiderov/AlinaChaiderov.svelte"
  // CYCLE 2
  import CycleTwo from "./CycleTwo.svelte"
  import HanniKamaly from "./hanni-kamaly/HanniKamaly.svelte"
  import StineJanvin from "./stine-janvin/StineJanvin.svelte"
  import HebaYAmin from "./heba-y-amin/HebaYAmin.svelte"
  import AnnaRunTryggvadottir from "./anna-run-tryggvadottir/AnnaRunTryggvadottir.svelte"

  // ** CONSTANTS
  const query =
    "*[ _type == 'cycleTwo' || _type == 'cycleOne' || _type == 'liquidFiction' || _type == 'editorial' ] | order(customOrder asc)"

  // *** STORES
  import { textContent, activePage } from "./stores.js"

  textContent.set(loadData(query, {}))

  async function loadData(query, params) {
    try {
      const res = await client.fetch(query, params)

      if (!Array.isArray(res)) {
        throw "Return data is not an array"
        return false
      }

      let contentContstruction = {
        liquidFiction: [],
        editorial: [],
        cycleOne: [],
      }

      contentContstruction.cycleOne = res.filter(
        (d) => get(d, "_type", "") === "cycleOne"
      )

      contentContstruction.cycleTwo = res.filter(
        (d) => get(d, "_type", "") === "cycleTwo"
      )

      contentContstruction.editorial = res.filter(
        (d) => get(d, "_type", "") === "editorial"
      )

      contentContstruction.liquidFiction = res.filter(
        (d) => get(d, "_type", "") === "liquidFiction"
      )

      return contentContstruction
    } catch (err) {
      console.log(err)
      Sentry.captureException(err)
    }
  }

  onMount(async () => {
    window.scrollTo(0, 0)
  })
</script>

<style lang="scss" global>:global(html),
:global(body) {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth; }
  :global(html.no-scroll),
  :global(body.no-scroll) {
    overflow: hidden; }

:global(body) {
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "liga";
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  font-family: "GT Pressura Mono", "Basis Grotesque Pro", "Akkurat-Mono", monospace; }

:global(*),
:global(*::before),
:global(*::after) {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

:global(::-webkit-scrollbar) {
  width: 0px; }

:global(a) {
  text-decoration: none;
  color: currentColor; }

:global(p) {
  margin-top: 0; }

:global(::selection) {
  background-color: #e4e4e4; }

:global(::-moz-selection) {
  background-color: #e4e4e4; }

:global(strong) {
  font-weight: 700; }

:global(.tns-liveregion.tns-visually-hidden) {
  display: none; }

:global(.pane) :global(img) {
  width: auto;
  max-height: 400px;
  max-width: calc(100% - 2rem); }

:global(.pane) :global(figure) {
  padding-left: 0;
  margin-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  :global(.pane) :global(figure) :global(img) {
    max-width: 100%;
    height: auto;
    max-width: 39.292rem; }

:global(.pane) :global(p) {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.333;
  max-width: 39.292rem; }

:global(.pane) :global(.editorial-audio) {
  width: 39.292rem;
  margin-bottom: 1em; }

:global(.pane.large) :global(p) {
  font-size: 21.33px;
  line-height: 1.333;
  font-weight: 300; }

:global(.pane) :global(.pane) :global(a) {
  border-bottom: 1px solid currentColor; }
  :global(.pane) :global(.pane) :global(a:hover) {
    border-bottom: 1px solid transparent; }

:global(video) {
  max-width: 100vw; }

:global(img) {
  max-width: 100vw;
  max-height: 100vh; }

:global(.timeline-event--hidden) {
  visibility: hidden;
  pointer-events: none; }

:global(.hanni-kamaly-page) :global(a),
:global(.hanni-kamaly-popup) :global(a) {
  border-bottom: 3px dashed currentColor; }
  :global(.hanni-kamaly-page) :global(a:hover),
  :global(.hanni-kamaly-popup) :global(a:hover) {
    border-bottom: 3px dashed transparent; }

:global(.hanni-kamaly-page) :global(img),
:global(.hanni-kamaly-popup) :global(img) {
  max-width: 90%; }

:global(.hanni-kamaly-page) :global(.detour),
:global(.hanni-kamaly-popup) :global(.detour) {
  border-bottom: 3px dashed yellow;
  cursor: pointer; }
  :global(.hanni-kamaly-page) :global(.detour:hover),
  :global(.hanni-kamaly-popup) :global(.detour:hover) {
    background: yellow;
    border-bottom: 3px solidtransparent; }

:global(.hanni-kamaly-page) :global(p),
:global(.hanni-kamaly-page) :global(h1),
:global(.hanni-kamaly-page) :global(h2),
:global(.hanni-kamaly-page) :global(h3),
:global(.hanni-kamaly-page) :global(blockquote),
:global(.hanni-kamaly-popup) :global(p),
:global(.hanni-kamaly-popup) :global(h1),
:global(.hanni-kamaly-popup) :global(h2),
:global(.hanni-kamaly-popup) :global(h3),
:global(.hanni-kamaly-popup) :global(blockquote) {
  width: 700px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto; }

:global(.hanni-kamaly-page) :global(blockquote),
:global(.hanni-kamaly-popup) :global(blockquote) {
  padding-left: 0em;
  font-size: 1.6em;
  font-family: serif;
  font-style: italic; }

:global(.hanni-kamaly-page) :global(img),
:global(.hanni-kamaly-popup) :global(img) {
  display: block;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  user-select: none; }

:global(.hanni-kamaly-page) :global(video),
:global(.hanni-kamaly-popup) :global(video) {
  max-width: 90%;
  display: block;
  max-height: 500px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  margin-top: 1em; }

:global(.hanni-kamaly-page) :global(audio),
:global(.hanni-kamaly-popup) :global(audio) {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  outline: none;
  margin-bottom: 1em; }

:global(.hanni-kamaly-page) :global(iframe),
:global(.hanni-kamaly-popup) :global(iframe) {
  margin-top: 1em;
  display: block;
  max-width: 700px;
  max-height: 500px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  margin-bottom: 1em; }

:global(.center-aligned) {
  display: block;
  text-align: center; }

:global(.spectrophilia) :global(figure) {
  padding: 0;
  margin: 0; }

:global(.spectrophilia) :global(img) {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  user-select: none; }

:global(.spectrophilia) :global(h2) {
  text-align: center;
  font-size: 2em;
  margin-top: 0; }

:global(.spectrophilia) :global(h3) {
  text-align: center;
  max-width: 30ch;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;
  margin-bottom: 2em; }

:global(.spectrophilia) :global(strong) {
  line-height: 1em;
  display: inline-block;
  padding-top: 1em;
  margin-bottom: 0.5em;
  letter-spacing: 0.2em;
  border-bottom: 2px solid black; }

:global(.hashtag) {
  border-bottom: 1px dashed black;
  cursor: pointer; }
  :global(.hashtag:hover) {
    border-bottom: 1px solid black; }

:global(.video-link) {
  border-bottom: 1px dashed #0096ff;
  cursor: pointer; }
  :global(.video-link:hover) {
    background: #0096ff;
    border-bottom: 1px solid #0096ff; }

:global(.popup-image) {
  border-bottom: 1px dashed #936500;
  cursor: pointer; }
  :global(.popup-image:hover) {
    background: #936500;
    border-bottom: 1px solid #936500; }

:global(.speech) :global(a) {
  border-bottom: 1px dotted black;
  cursor: pointer; }
  :global(.speech) :global(a:hover) {
    border-bottom: 1px solid black; }</style>

<Orb />

<Router>
  <Route path="/" component={Landing} />
  <Route path="liquid-fiction" component={About} />
  <Route path="editorial" component={Editorial} />
  <Route path="spectrophilia" component={Spectrophilia} />

  <!-- CYCLE 1 -->
  <Route path="cycle-1" component={CycleOne} />
  <Route path="eeefff" component={EEEFFF} />
  <Route path="olof-marsja" component={OlofMarsja} />
  <Route path="alina-chaiderov" component={AlinaChaiderov} />
  <!-- CYCLE 2 -->
  <Route path="cycle-2" component={CycleTwo} />
  <Route path="hanni-kamaly" component={HanniKamaly} />
  <Route path="stine-janvin" component={StineJanvin} />
  <Route path="heba-y-amin" component={HebaYAmin} />
  <Route path="anna-run-tryggvadottir" component={AnnaRunTryggvadottir} />
  <Route path="anna-run-tryggvadottir/:slug" component={AnnaRunTryggvadottir} />
</Router>
